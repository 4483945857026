import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import '../assets/styles/community-page.css';

const CommunityPage = () => {
  return (
    <div className="community-page">
      <div className="community-hero">
        <Container>
          <div className="community-content">
            <div className="text-content">
              <h1 className="community-title">Genbook : AI Art and Community</h1>
              <p className="community-subtitle">Create unlimited images using Genbook, Show your creativity to the World</p>
              <div className="app-links">
                <Button 
                  variant="dark" 
                  className="store-button apple-store"
                  href="https://apps.apple.com/us/app/genbook-ai-image-generator/id6738500891"
                >
                  <FaApple className="store-icon" />
                  <div className="button-text">
                    <span className="button-subtitle">Download on the</span>
                    <span className="button-title">App Store</span>
                  </div>
                </Button>
                
                <Button 
                  variant="dark" 
                  className="store-button play-store"
                  href="https://play.google.com/store/apps/details?id=com.dimensionforge.genbook&hl=en_IN"
                >
                  <FaGooglePlay className="store-icon" />
                  <div className="button-text">
                    <span className="button-subtitle">GET IT ON</span>
                    <span className="button-title">Google Play</span>
                  </div>
                </Button>
              </div>
            </div>
            
            <div className="app-showcase">
              <div className="app-preview">
                <img 
                  src="/assets/images/Genbook-mobile-view.png" 
                  alt="Genbook App Preview" 
                  className="app-preview-image"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="features-section">
        <h2 className="features-title">Features You'll Love</h2>
        
        <div className="features-grid">
          {[
            {
              title: 'AI Image Generation',
              description: 'Bring your ideas to life with cutting-edge AI that creates breathtaking visuals from text prompts.'
            },
            {
              title: 'Image Editing Tools',
              description: 'Fine-tune every detail of your creation with intuitive editing features.'
            },
            {
              title: 'Diverse Artistic Styles',
              description: 'Explore a wide range of styles, from historic and classical art to futuristic and high-tech aesthetics.'
            },
            {
              title: 'Rich Color Palettes',
              description: 'Experiment with a spectrum of colors to make your artwork truly unique.'
            },
            {
              title: 'Extreme Customization',
              description: 'Adjust every element of your image for full creative control.'
            },
            {
              title: 'Community & Networking',
              description: 'Share your art, connect with other creators, and get inspired by a global community of visionaries.'
            }
          ].map((feature, index) => (
            <div 
              key={index}
              className="feature-card"
            >
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
        
        <div className="cta-section">
          <h2 className="cta-title">Transform your ideas into extraordinary visuals</h2>
          <p className="cta-description">
            Share them with a world eager to see your talent. Whether you're crafting art for fun, 
            social sharing, or professional projects, GenBook empowers you to make your vision a reality.
          </p>
          <Button variant="primary" size="lg" className="download-button">
            Download GenBook Today
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommunityPage;