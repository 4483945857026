import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaLinkedin, FaGithub,FaHome, FaEnvelope,} from 'react-icons/fa';
import footerImage from '../assets/images/company.png';
import '../assets/styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer text-center text-lg-start text-muted">
      <Container>
      <Row className="mt-3">
          <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
            <h6 className="fw-bold mb-4">
              <span className="landing-navbar-title">Dimension Forge</span>
            </h6>
            <div className="d-flex justify-content-center align-items-center">
              <a href="https://www.instagram.com/genbookstudio" className="me-4 text-reset">
                <FaInstagram className='footer-social-icon'/>
              </a>
              <a href="https://www.linkedin.com/company/dimension-forgeai" className="me-4 text-reset">
                <FaLinkedin className='footer-social-icon'/>
              </a>
            </div>
          </Col>

          <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4 footer-subheading">Products</h6>
            <p>
              <a href="/community" className="text-reset">Genbook</a>
            </p>
            <p>
              <a href="#!" className="text-reset">Genbook Studio</a>
            </p>
          </Col>

          <Col md={3} lg={2} xl={2} className="mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4 footer-subheading">Useful links</h6>
            <p>
              <a href="https://dimensionforge.ai/" className="text-reset">About Us</a>
            </p>
            <p>
              <a href="/genbook/privacy-policy" className="text-reset">Privacy Policy</a>
            </p>
            <p>
              <a href="/genbook/terms-of-use" className="text-reset">Terms and Conditons</a>
            </p>
          </Col>

          <Col md={4} lg={3} xl={3} className="mx-auto mb-md-0 mb-4">
            <h6 className="text-uppercase fw-bold mb-4 footer-subheading">Contact</h6>
            <p><FaHome className="me-3" />86-90 Paul Street, London</p>
            <p>
              <FaEnvelope className="me-3" />
              contactus@dimensionforge.ai
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <img src={footerImage} alt="Footer" className="footer-image" />
            <h2 id='footer-copyright'>© Genbook, 2024. All rights reserved.</h2>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
